import React, {useCallback} from 'react';
import {Button, ButtonGroup, Col, Input, Row} from "reactstrap";
import {CheckBox, ImagePicker} from 'react-admin-base-bootstrap';
import {Field} from "./Field";

export function* GetTemplateParamsById(parameter, prev_id) {
    if ((parameter.type & 1) === 1) {
        for (var child of parameter.childs) {
            yield* GetTemplateParamsById(child, parameter.id);
        }
    } else {
        yield {
            ...parameter,
            id: prev_id ? (prev_id + '.' + parameter.id) : parameter.id
        };
    }
}

const GroupChild = React.memo(function (props) {
    const { parameter, onChange } = props;

    const onChangeCached = useCallback(val => onChange(old_val => ({ ...(old_val || {}), [parameter.id]: val && val.apply ? val(old_val ? old_val[parameter.id] : null) : val })) , [onChange, parameter]);

    return <TemplateParameter
        {...props}
        onChange={onChangeCached}
    />;
});

function Group({ id, parameter, value, onChange, className, wrapRoot, wrap, wrapper, disabled, validate }) {
    //const onChangeCached = useCallback(val => onChange(value => ({ ...value, ...val })), [onChange]);

    return <div className={className}>
        { parameter.name && <p className="text-danger mb-3"><b>{ parameter.name }</b></p> }
        {
            (wrapRoot || (a => a))(parameter.childs
                .map(a => (wrap || (a => a))(<GroupChild
                    key={a.id}
                    id={ !id ? a.id : (id+'.'+a.id) }
                    parameter={a}
                    value={(value && value[a.id]) || null}
                    onChange={onChange}
                    wrapper={wrapper}
                    validate={validate}
                    disabled={disabled}
                />)))
        }
        { parameter.description && <p className="text-muted">
            { parameter.description }
        </p> }
    </div>
}

function HGroupWrap(children) {
    return <div key={children.key}>{children}</div>;
}

function HGroup(props) {
    return <Group
        {...props}
        className="hgroup"
        wrapRoot={HGroupWrap}
    />;
}

function VGroupWrap(children) {
    return <Col key={children.props.key}>{children}</Col>;
}

function VGroupWrapRoot(children) {
    return <Row>{children}</Row>;
}

function VGroup(props) {
    return <Group
        {...props}
        className="vgroup"
        wrapRoot={VGroupWrapRoot}
        wrap={VGroupWrap}
    />;
}

function XGroupWrap(children) {
    return <Col md="4" key={children.key}>{children}</Col>;
}

function XGroup(props) {
    return <Group
        {...props}
        className="xgroup"
        wrapRoot={VGroupWrapRoot}
        wrap={XGroupWrap}
    />;
}

const TextOptions = {
    1: a => (a || '').toLocaleUpperCase('tr'),
    2: a => (a || '').toLocaleLowerCase('tr'),
    3: a => a.split(' ').map(a => (a[0] || '').toLocaleUpperCase('tr') + (a || '').substr(1).toLocaleLowerCase('tr')).join(" ")
};

function useTransformingOnChange(type, onChange) {
    const fnc = type && TextOptions[type];

    return useCallback(function(e) {
        const input = e.currentTarget;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        
        onChange(fnc ? fnc(e.currentTarget.value) : e.currentTarget.value);
        
        setTimeout(function() {
            input.setSelectionRange(start, end);
        }, 1);
    }, [fnc, onChange]);
}

function Date({ id, parameter, value, onChange, disabled, validate }) {
    return <Field title={parameter.name} noTitle={false} validatorTitle={id} type={!disabled && validate && ['required']} desc={parameter.description}>
        <Input
            type="date"
            disabled={disabled}
            value={value || ''}
            onChange={a => onChange(a.currentTarget.value)}
            placeholder={parameter.placeholder}
        />
    </Field>;
}

function Text({ id, parameter, value, onChange, disabled, validate }) {
    const _onChange = useTransformingOnChange(parameter.text_options, onChange);

    return <Field title={parameter.name} noTitle validatorTitle={id} type={!disabled && validate && ['required']} desc={parameter.description}>
        <Input
            type="text"
            disabled={disabled}
            value={value || ''}
            onChange={_onChange}
            placeholder={parameter.placeholder || parameter.name}
            maxLength={parameter.max || undefined}
        />
    </Field>;
}

function Image({ id, parameter, value, onChange, validate, disabled }) {
    return <Field title={parameter.name} validatorTitle={id} type={!disabled && validate && ['required']} desc={parameter.description}>
        <ImagePicker
            disabled={disabled}
            value={value || ''}
            onChange={onChange}
            width={parameter.width}
            height={parameter.height}
            type={parameter.file_type === 1 ? "jpg" : "png"}
            exact
        />
    </Field>;
}

function RadioGroup({ value, children }) {
    return children;
}

function Select({ parameter, value, onChange, disabled, validate }: any) {
    return <Field title={parameter.name} type={!disabled && validate && ['required']} desc={parameter.description}>
        {parameter.select_type === 1 ? <Input type="select" value={value || ''} disabled={disabled} onChange={a => onChange(a.currentTarget.value)}>
            <option value="">Please select</option>
            { (parameter.values || []).map(a => <option key={a.key} value={a.value}>{a.key}</option> )}
    </Input> : parameter.select_type === 2 ? <RadioGroup value={value}>
        <Row>
            {(parameter.values || []).map(o => <Col key={o.key}>
                <CheckBox
                    type="radio"
                    disabled={disabled}
                    label={o.key}
                    checked={value === o.value}
                    onClick={_ => onChange(o.value)}
                />
            </Col>)}
        </Row>
    </RadioGroup> : <ButtonGroup value={value} className="d-flex w-100">
        { (parameter.values || []).map(o => <Button
            key={o.key}
            type="button"
            color="primary"
            outline={value !== o.value}
            disabled={disabled}
            onClick={a => onChange(o.value)}>
            {o.key}
        </Button>)}
    </ButtonGroup> }
    </Field>;
}

const mapping = {
    1: HGroup,
    3: VGroup,
    5: XGroup,

    2: Text,
    6: Text,
    10: Date,
    18: Text,
    34: Select,

    8: Image,
    16: Image
};

const TemplateParameter = React.memo(function({ id, parameter, value, onChange, validate, wrapper, disabled }) {
    const Component = mapping[parameter.type];
    const RenderComponent = wrapper || Component;

    return <RenderComponent
        id={id}
        parameter={parameter}
        value={value}
        onChange={onChange}
        validate={validate}
        wrapper={wrapper}
        OriginalComponent={Component}
        disabled={disabled}
    />;
});

export default TemplateParameter;