import React from 'react';
import {createFetchStore} from 'react-suspense-fetch';
import api from "../api";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Player} from '@lottiefiles/react-lottie-player';
import { useMediaQuery } from 'react-responsive';

const store = createFetchStore(() => api("/portal/banners"));

function useIsMobile() {
    return useMediaQuery({ maxWidth: 767 });
}

export default function Banners({ children }) {
    const isMobile = useIsMobile();
    store.prefetch();
    const allBanners = store.get();
    const hasAnyMobileOnlyBanners = !!allBanners
        .find(a => a.mobile_only);
    
    const banners = !hasAnyMobileOnlyBanners ? allBanners : allBanners
        .filter(a => a.mobile_only === isMobile);
        
    const detailed = banners.length > 1;
    
    return <Carousel showThumbs={false} showStatus={detailed} showIndicators={detailed}>
        {
            banners
                .map((a,i) => <div key={i}>
                    { a.file && !/\.json$/.test(a.file.name) && <img src={a.file.access_url} alt={a.title} /> }
                    { (a.lottie_link || (a.file && /\.json$/.test(a.file.name))) && <Player
                        autoplay
                        loop
                        src={a.lottie_link || a.file.access_url}
                    /> }
                    { a.title && a.subtitle && <div>
                        <h1>{ a.title }</h1>
                        <h2>{ a.subtitle }</h2>
                    </div> }
                </div>)
        }
    </Carousel>;
}
