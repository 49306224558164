import React, {createContext, useContext, useEffect, useMemo} from 'react';
import {createFetchStore} from 'react-suspense-fetch';
import api from "../api";
import LanguageProvider from "./LanguageProvider";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ReactPixel from 'react-facebook-pixel';

const BrandContext = createContext();
const store = createFetchStore(() => api("/portal"));

export function useBrand() {
    return useContext(BrandContext);
}

export default function BrandProvider({ children }) {
    store.prefetch();
    const brand = store.get();

    const stripe = useMemo(() => (brand.stripe_account && loadStripe(brand.stripe_account.public_key)) || null, [ brand ]);

    useEffect(function() {
        document.title = brand.name;
        
        if (brand.facebook_pixel_id) {
            ReactPixel.init(brand.facebook_pixel_id, {}, { autoConfig: true, debug: false });
        }
        
        if (brand.theme) {
            let root = document.documentElement;

            root.style.setProperty('--body-color', brand.theme.body_color);
            root.style.setProperty('--header-bg-color', brand.theme.header_bg_color);
            root.style.setProperty('--header-text-color', brand.theme.header_text_color);
            root.style.setProperty('--header-hover-color', brand.theme.header_hover_color);
            root.style.setProperty('--title-color', brand.theme.title_color);
        }
    }, [ brand ]);

    return <Elements stripe={stripe}>
        <BrandContext.Provider value={brand}>
            <LanguageProvider>
                { children }
            </LanguageProvider>
        </BrandContext.Provider>
    </Elements>;
}

