import api from "./api";

export default function tokenized_api(access_token, refresh_token, update) {
    var access_token_task = null

    if (refresh_token == null) {
        return null;
    }

    if (access_token == null) {
        _refresh_token();
    }

    function get_access_token() {
        if (access_token_task) {
            return access_token_task;
        }

        return Promise.resolve(access_token);
    }

    async function _refresh_token() {
        if (access_token_task) {
            return access_token_task;
        }

        access_token_task = _refresh_token();
        const data = await access_token_task;
        access_token_task = null;
        return data;

        async function _refresh_token() {
            let formData = new FormData();

            formData.append('client_id', '3da4385d-5166-4c1f-9c4b-49afe82be47e');
            formData.append('client_secret', '123456');
            formData.append('grant_type', 'refresh_token');
            formData.append('refresh_token', refresh_token);

            try {
                const data = await api('/portal/token', {
                    method: 'POST',
                    body: formData
                });

                access_token = data.access_token;
                update(access_token);
                return access_token;
            } catch(e) {
                update(null);
            }
        }
    }

    return async function a(url, params) {
        const access_token = await get_access_token();
        console.log(access_token);
        const newParams = {...(params || {}), headers: {'Accept': 'application/json', 'Authorization': 'Bearer ' + access_token, ...((params || {}).headers || {})}};

        const data = await fetch(process.env.REACT_APP_ENDPOINT + url, newParams);

        switch(data.status) {
            case 403:
                await _refresh_token();
                return await a(url,params);
            case 200:
            case 201:
                return await data.json();
            case 204:
                return true;
            case 404:
                throw new Error(`404 on ${url}`);
            default:
                let error;
                try {
                    error = await data.json();
                } catch(e) {
                    throw new Error('Invalid response on ' + url);
                }

                throw new Error(error.message);
        }
    };
};
