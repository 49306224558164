import {
    Alert,
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {useCallback, useState} from "react";
import {ValidatorProvider} from 'react-admin-base';
import {Validator, CheckBox} from 'react-admin-base-bootstrap';
import ForcedModal from "../components/ForcedModal";
import Swal2 from 'sweetalert2';
import {Navigate} from "react-router-dom";
import {useUser} from "../providers/UserProvider";
import {FormattedMessage, useIntl} from "react-intl";
import api from "../api";

const topics = [
    'Questions',
    'Delivery issues',
    'Payment issues',
    'Technical support',
    'Other'
];

export default function Contact() {
    const user = useUser();
    const intl = useIntl();

    const [ data, setData ] = useState({ name: (user && user.name) || '', email: (user && user.email) || '' });
    const [ loading, setLoading ] = useState(false);
    const [ done, setDone ] = useState(false);

    const onSubmit = useCallback(async function(e) {
        e.preventDefault();

        setLoading(true);
        try {
            await api("/portal/contact-form", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(data)
            })

            setDone(true);
            await Swal2.fire(
                intl.formatMessage({ id: 'SUCCESS' }),
                intl.formatMessage({ id: 'CONTACT_SENT' }),
                'success'
            );
        } finally {
            setLoading(false);
        }

    }, [ intl, data, setLoading, setDone ]);

    if (done) {
        return <Navigate to="/" />;
    }

    return <ForcedModal size="lg">
        <ModalHeader>
            <FormattedMessage id="CONTACT" />
        </ModalHeader>
        <ValidatorProvider>
            <Form onSubmit={onSubmit}>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label><FormattedMessage id="NAME" /></Label>
                                <Validator name="name" type="required">
                                    <Input
                                        type="text"
                                        value={data.name || ''}
                                        onChange={a => setData({ ...data, name: a.currentTarget.value })}
                                        disabled={!!loading || done}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label><FormattedMessage id="EMAIL_ADDRESS" /></Label>
                                <Validator name="email" type="required|email">
                                    <Input
                                        type="text"
                                        value={data.email || ''}
                                        onChange={a => setData({ ...data, email: a.currentTarget.value })}
                                        disabled={!!loading || done}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label><FormattedMessage id="SUBJECT" /></Label>
                        <Validator name="subject" type="required">
                            <Input
                                id="subject"
                                value={data.subject || ''}
                                onChange={a => setData({ ...data, subject: a.currentTarget.value })}
                                disabled={!!loading || done}
                                type="select">
                                <option value=''>Please select</option>
                                { topics.map(a => <option value={a}>{a}</option>) }
                            </Input>
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="MESSAGE" /></Label>
                        <Validator name="message" type="required">
                            <Input
                                type="textarea"
                                rows="6"
                                value={data.message || ''}
                                onChange={a => setData({ ...data, message: a.currentTarget.value })}
                                disabled={!!loading || done}
                            />
                        </Validator>
                    </FormGroup>
                    { loading && <Alert color="warning">
                        <FormattedMessage id="SUBMITTING" />
                    </Alert> }
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" block color="primary" disabled={!!loading || done}>
                        <FormattedMessage id="SEND" />
                    </Button>
                    <Button type="button" outline block color="danger">
                        <FormattedMessage id="CLOSE" />
                    </Button>
                </ModalFooter>
            </Form>
        </ValidatorProvider>
    </ForcedModal>;
}