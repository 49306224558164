import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {Validator, ValueValidator, CheckBox} from "react-admin-base-bootstrap";
import {CardElement, PaymentRequestButtonElement, useElements, useStripe} from "@stripe/react-stripe-js";
import PriceSpan from "./PriceSpan";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useUser} from "../providers/UserProvider";
import {useBrand} from "../providers/BrandProvider";
import { Step } from './Steps';
import {FormattedMessage} from "react-intl";

const CardElementOptions = {
    hidePostalCode: true,
    style: {
        base: {
            lineHeight: '1.429',
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

function ValidatedCardElement() {
    const [ state, setState ] = useState();

    return <ValueValidator name="Card" value={state} type="required|card">
        <div className="form-control">
            <CardElement
                options={CardElementOptions}
                onChange={setState}
            />
        </div>
    </ValueValidator>;
}

function NewCreditCard({ value, setValue }) {
    const user = useUser();

    return <>
        <Row>
            <Col md="6">
                <FormGroup>
                    <Label><FormattedMessage id="CARDHOLDER_NAME" /></Label>
                    <Validator name="Cardholder Name" type="required">
                        <Input
                            type="text"
                            value={value.name || ''}
                            onChange={a => setValue({ ...value, name: a.currentTarget.value })}
                        />
                    </Validator>
                </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup>
                    <Label><FormattedMessage id="CARD_INFO" /></Label>
                    <ValidatedCardElement />
                </FormGroup>
            </Col>
        </Row>
        { !!user && <CheckBox
            id="saveCard"
            type="checkbox"
            className="mb-2"
            label={<FormattedMessage id="SAVE_CARD" />}
            checked={!!value.saveCard}
            onChange={a => setValue({ ...value, saveCard: !value.saveCard })}
        /> }
    </>;
}

function usePaymentRequest(name, price) {
    const brand = useBrand();
    const stripe = useStripe();
    const [ paymentRequest, setPaymentRequest ] = useState(null);

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: brand.currency.toLowerCase(),
                total: {
                    label: name,
                    amount: price * 100,
                },
                requestPayerName: false,
                requestPayerEmail: false
            });

            pr.canMakePayment()
                .then(result => {
                    console.log('Can Make Payment:', result);
                    if (result) {
                        setPaymentRequest(pr);
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }, [stripe, brand, name, price]);

    return paymentRequest;
}

export function usePaymentState() {
    return useState({ type: 2 });
}

export default forwardRef(function(props, ref) {
    const { name, value, setValue, price } = props;
    const stripe = useStripe();
    const elements = useElements();
    const pr = usePaymentRequest(name, price);

    useImperativeHandle(ref, () => ({
        async getPaymentInfo() {
            if (value.type === 2) {
                const cardElement = elements.getElement(CardElement);

                const {error, paymentMethod} = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });

                if (error)
                    throw error;

                return {
                    payment_method_id: paymentMethod.id
                };
            }
        },
        async handleCardAction(e) {
            const { paymentIntent, error } = await stripe.handleCardAction(e.data.token);
            if (error)
                throw error;

            return {
                payment_intent_id: paymentIntent.id
            };
        }
    }), [stripe, elements, value]);

    return <Step {...props}>
        { value.type === 3 && !!pr && <PaymentRequestButtonElement
            options={{ paymentRequest: pr }}
        /> }

        {/* !!user && <CheckBox
            id="radioSavedCard"
            type="radio"
            checked={value.type === 1}
            onChange={a => setValue({ type: 1 })}
            label="Use my saved card"
            className="mb-2"
            disabled={!!user}
        /> */}

        <CheckBox
            id="radioNewCard"
            type="radio"
            checked={value.type === 2}
            onChange={a => setValue({ type: 2 })}
            label={<FormattedMessage id="PAY_WITH_CARD" />}
            className="mb-2"
        />

        { value.type === 2 && <NewCreditCard
            value={value}
            setValue={setValue}
        /> }

        <div className="mb-3">
            <ValueValidator name="Card Acceptance" value={!!value.accepted} type="required|accepted">
                <CheckBox
                    id="acceptPayment"
                    type="checkbox"
                    label={<FormattedMessage
                        id="CONFIRM_PAYMENT"
                        values={{
                            price: <b>
                                <PriceSpan price={price} />
                            </b>
                        }}
                    />}
                    checked={!!value.accepted}
                    onChange={a => setValue({ ...value, accepted: !value.accepted })}
                />
            </ValueValidator>
        </div>
    </Step>;
});
