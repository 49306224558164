import {useBrand} from "../providers/BrandProvider";
import {FormattedMessage} from "react-intl";

export default function PriceSpan({ price }) {
    const brand = useBrand();

    if (!price)
        return <FormattedMessage id="FREE" />;

    switch(brand.currency) {
        case 'USD':
            return "$" + (+price).toFixed(2);
        case 'EUR':
            return (+price).toFixed(2) + "€";
        case 'PLN':
            return (+price).toFixed(2) + "zł";
        case 'TRY':
            return (+price).toFixed(2) + "₺";
        case 'JPY':
            return "¥" + (price);
        default:
            return null;
    }
}