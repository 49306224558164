import Header from "./Header";
import Footer from "./Footer";

export default function Layout({ children }) {
    return <div className="main">
        <Header />
        <div className="content">
            { children }
        </div>
        <Footer />
    </div>;
}
