import ForcedModal from "./ForcedModal";
import {Alert, Button, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {ValidatorProvider} from "react-admin-base";
import {Validator} from "react-admin-base-bootstrap";
import {useCallback, useState} from "react";
import api from "../api";
import {FormattedMessage} from "react-intl";

export default function OtpModal({ url, email, onValidated }) {
    const [ code, setCode ] = useState();
    const [ error, setError ] = useState();
    const [ loading, setLoading ] = useState(false);

    const submit = useCallback(async function(e) {
        e.preventDefault();

        setError(null);
        setLoading(true);
        try {
            await api(url + '&code=' + code);
            onValidated(url + '&code=' + code);
        } catch(e) {
            console.error(e);
            setError(e);
        } finally {
            setLoading(false);
        }
    }, [url, setLoading, onValidated, code, setError]);

    return <ForcedModal>
        <ModalHeader>
            <FormattedMessage id="OTP_VALIDATION" />
        </ModalHeader>
        <ModalBody>
            <ValidatorProvider>
                <Form onSubmit={submit}>
                    <FormGroup>
                        <Label><FormattedMessage id="CODE" /></Label>
                        <Validator name="code" type="required|numberic">
                            <Input
                                type="text"
                                value={code || ''}
                                onChange={a => setCode(a.currentTarget.value)}
                                disabled={!!loading}
                            />
                        </Validator>
                    </FormGroup>
                    { error && <Alert color="danger" size="sm" toggle={a => setError(null)}>
                        { JSON.stringify(error) }
                    </Alert> }
                    <Button type="submit" block color="primary" disabled={!!loading}>
                        <FormattedMessage id="VALIDATE" />
                    </Button>
                </Form>
            </ValidatorProvider>
        </ModalBody>
        <ModalFooter>
            <p className="text-muted">
                <FormattedMessage
                    id="HAS_SENT_CODE"
                    values={{
                        email: <b>{email}</b>
                    }}
                />
            </p>
        </ModalFooter>
    </ForcedModal>;
}
