import React from 'react';
import ForcedModal from "./ForcedModal";
import {ModalBody} from "reactstrap";

export default function LoadingModal(props) {
    const { children } = props;

    return <React.Suspense fallback={<ForcedModal>
        <ModalBody>
            Loading
        </ModalBody>
    </ForcedModal>}>
        { children }
    </React.Suspense>;
}
