import {Navigate, useParams} from "react-router-dom";
import ForcedModal from "../components/ForcedModal";
import {FormGroup, FormText, Input, Label, ModalBody, ModalHeader} from "reactstrap";
import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from "react";
import {store} from "./template";
import TemplateParameter from "../components/TemplateParameter";
import {Validator,CheckBox} from 'react-admin-base-bootstrap';
import StepContainer, {Step} from "../components/Steps";
import {useBrand} from "../providers/BrandProvider";
import Payment, {usePaymentState} from "../components/Payment";
import api from "../api";
import Swal2 from "sweetalert2";
import {FormattedMessage, useIntl} from "react-intl";
import { PrivacyPolicyPopup } from "./privacy-policy";
import { useQueryParams } from "react-admin-base";

function useMagicLink() {
    const params = useParams();
    const [ isValid, setIsValid ] = useState();
    const [ credit, setCredit ] = useState();
    const { sig } = useQueryParams();

    const url = process.env.REACT_APP_ENDPOINT + `/portal/template/${params.id}/render` + window.location.search;

    const update = useCallback(async function() {
        const response = await fetch(
                url,
                {
                    method: 'HEAD'
                });

        setIsValid(response.status === 204);
        setCredit(response.headers.get('x-remaining-credit'));
        }, [url, sig, setIsValid, setCredit]);

    useEffect(function() {
        if (sig) {
            update();
        }
    }, [update]);

    return useMemo(() => [!!sig, isValid, credit, url], [!!sig, isValid, credit, url]);
}
export default function Customize() {
    const brand = useBrand();
    const params = useParams();
    store.prefetch(params.id);
    const template = store.get(params.id);
    const [ renderParams, setRenderParams ] = useState({});
    const { email } = useQueryParams();
    const [ options, setOptions ] = useState({ email: email || '' });
    const payment = useRef();
    const [ paymentOptions, setPaymentOptions ] = usePaymentState();
    const [ success, setSuccess ] = useState(false);
    const intl = useIntl();
    const [ showPrivacyPolicy, togglePrivacyPolicy ] = useReducer(a => !a, false);

    const [ isSignature, isValid, credit, url ] = useMagicLink();

    const sendToRender = useCallback(async function (payment) {
        await api(isSignature ? url : `/portal/template/${template.template.slug}/render`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...options,
                values: renderParams,
                payment
            })
        });
    }, [template, options, renderParams, isSignature, url]);

    const onSubmit = useCallback(async function() {
        let paymentInfo = null;

        if (payment.current) {
            paymentInfo = await payment.current.getPaymentInfo();
        }

        try {
            try {
                await sendToRender(paymentInfo);
            } catch (e) {
                if (e.name === 'PaymentActionRequired') {
                    paymentInfo = await payment.current.handleCardAction(e);
                    await sendToRender(paymentInfo);
                } else {
                    throw e;
                }
            }

            setSuccess(true);
            await Swal2.fire(
                intl.formatMessage({ id: 'SUCCESS' }),
                intl.formatMessage({ id: 'VIDEO_SUBMITTED' }),
                'success'
            );
        } catch(e) {
            console.error(e);
            await Swal2.fire(
                intl.formatMessage({ id: 'ERROR' }),
                (e && ((e.data && e.data.message) || e.message))
                    || intl.formatMessage({ id: 'UNKNOWN_ERROR' }),
                'error');
        } finally {
        }
    }, [payment, intl, setSuccess, sendToRender]);

    if (success)
        return isSignature ? null : <Navigate to="/" />;

    if (isSignature) {
        if (isValid === false)
            return <ForcedModal size="lg">
                <ModalHeader>
                    <FormattedMessage id="INVALID_LINK" />
                </ModalHeader>
                <ModalBody>
                    <p><FormattedMessage id="INVALID_LINK_DESCRIPTION" /></p>
                </ModalBody>
        </ForcedModal>;

        if (!isValid)
            return null;
    }

    return <ForcedModal size="lg">
        <ModalHeader>
            { template.name || template.template.name }
        </ModalHeader>
        <ModalBody>
            <StepContainer onSubmit={onSubmit}>
                <Step title="STEP_INFO">
                    <TemplateParameter
                        parameter={template.template.parameter}
                        value={renderParams}
                        onChange={setRenderParams}
                        validate
                    />
                </Step>
                <Step title="STEP_DELIVERY">
                    <FormGroup>
                        <Label><FormattedMessage id="EMAIL_ADDRESS" /></Label>
                        <Validator name="email" type="required|email">
                            <Input
                                type="text"
                                value={options.email || ''}
                                onChange={a => setOptions({ ...options, email: a.currentTarget.value })}
                            />
                        </Validator>
                        <FormText color="muted"><FormattedMessage id="EMAIL_ADDRESS_DESC" /></FormText>
                    </FormGroup>
                    { brand.privacy_policy_enabled && <FormGroup>
                        <Validator name="privacy_policy" type="accepted">
                            <CheckBox
                                id="acceptTos"
                                type="checkbox"
                                checked={options.tos1 || false}
                                onChange={a => setOptions({ ...options, tos1: !options.tos1 })}
                                label={<FormattedMessage
                                    id="ACCEPT_PRIVACY_POLICY"
                                    values={{
                                        a: text => <a href="/privacy-policy" onClick={e => { e.preventDefault(); togglePrivacyPolicy(); }}>{text}</a>
                                    }}
                                />}
                            />
                        </Validator>
                    </FormGroup> }
                    { showPrivacyPolicy && <PrivacyPolicyPopup onClose={togglePrivacyPolicy} /> }
                </Step>
                { !isSignature && !!template.price && <Payment
                    ref={payment}
                    title="STEP_PAYMENT"
                    name={template.name || template.template.name}
                    price={template.price}
                    value={paymentOptions}
                    setValue={setPaymentOptions}
                /> }
            </StepContainer>
        </ModalBody>
    </ForcedModal>
}