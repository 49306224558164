import './App.css';
import './main.scss';
import 'react-aspect-ratio/aspect-ratio.css'
import 'react-admin-base-bootstrap/assets/main.css';
import BrandProvider from "./providers/BrandProvider";
import LoadingProvider from "./components/LoadingProvider";
import AuthProvider from "./providers/AuthProvider";
import UserProvider from "./providers/UserProvider";
import Router from "./router";
import {BrowserRouter} from "react-router-dom";
import UploadConfig from "./UploadConfig";
import 'sweetalert2/dist/sweetalert2.min.css';
import ValidatorOptionsProvider from "./providers/ValidatorOptionsProvider";

function App() {
  return (
    <LoadingProvider>
      <BrandProvider>
          <AuthProvider>
              <UserProvider>
                  <UploadConfig>
                      <ValidatorOptionsProvider>
                          <BrowserRouter>
                            <Router />
                          </BrowserRouter>
                      </ValidatorOptionsProvider>
                  </UploadConfig>
              </UserProvider>
          </AuthProvider>
      </BrandProvider>
    </LoadingProvider>
  );
}

export default App;
