import {FormGroup, FormText, Label} from "reactstrap";
import {Validator} from "react-admin-base-bootstrap";
import React from "react";

export function Field({ title, validatorTitle, type, children, desc }) {
    const isRequired = (type && type.indexOf("required") !== -1) || false;

    return <FormGroup>
        <Label>{title}{isRequired && <i className="fas fa-asterisk ms-1 text-danger field-required" />}</Label>
        <Validator name={validatorTitle || title || 'Unk'} type={type}>
            { children }
        </Validator>
        { desc && <FormText color="muted">
            {desc}
        </FormText>}
    </FormGroup>
}