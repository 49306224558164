import {Modal, ModalHeader} from "reactstrap";
import React, {useReducer} from "react";
import {Navigate} from 'react-router-dom';

export default function ForcedModal({ children, size }) {
    const [ isOpen, toggleIsOpen ] = useReducer(a => !a, true, a => a);

    return <Modal size={size} isOpen toggle={toggleIsOpen}>
        { !isOpen && <Navigate to="/" /> }
        { React.Children.map(children, a => a.type === ModalHeader ? React.cloneElement(a, { toggle: toggleIsOpen }) : a) }
    </Modal>;
}
