import {Navigate, Route, Routes} from 'react-router-dom';
import Home from "./pages/home";
import Login from "./auth/login";
import Register from "./auth/register";
import ForgotPassword from "./auth/forgotpassword";
import {useAuth} from "./providers/AuthProvider";
import Template from "./pages/template";
import MyVideos from "./pages/my-videos";
import Contact from "./pages/contact";
import {useBrand} from "./providers/BrandProvider";
import PrivacyPolicy from "./pages/privacy-policy";
import AboutUs from './pages/about-us';

export default function Router() {
    return <Routes>
        <Route path="/my-videos" element={<MyVideos />} />
        <Route path="*" element={<Home />} />
    </Routes>;
}

export function ModalRoutes() {
    const [api] = useAuth();
    const brand = useBrand();
    
    if (brand.disable_login)
        return <AuthenticatedRoutes />;

    return api ? <AuthenticatedRoutes /> : <NotAuthenticatedRoutes />;
}

function AuthenticatedRoutes() {
    return <PublicRoutes />;
}

function NotAuthenticatedRoutes() {
    return <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="*" element={<PublicRoutes />} />
    </Routes>;
}

function PublicRoutes() {
    const brand = useBrand()
    
    return <Routes>
        { !!brand.privacy_policy_enabled && <Route path="/privacy-policy" element={<PrivacyPolicy />} /> }
        { !!brand.about_us_enabled && <Route path="/about-us" element={<AboutUs />} /> }
        { !!brand.contact_form_enabled && <Route path="/contact" element={<Contact />} /> }
        <Route path="/template/:id/*" element={<Template />} />
    </Routes>;
}
