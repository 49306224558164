import {useBrand} from "../providers/BrandProvider";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

export default function Footer() {
    const brand = useBrand();

    if (!brand.copyright_text)
        return null;

    return <footer className="footer mt-5">
        <FormattedMessage id="FOOTER_TEXT" values={{ copyright_text: brand.copyright_text }} />
        { brand.privacy_policy_enabled && <div><Link to="/privacy-policy">
            <FormattedMessage id="PRIVACY_POLICY" />
        </Link></div> }
    </footer>;
}
