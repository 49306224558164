import React, {useMemo, useState} from 'react';
import {createFetchStore} from 'react-suspense-fetch';
import api from "../api";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Col,  Row} from "reactstrap";
import {Link} from "react-router-dom";
import PriceSpan from "./PriceSpan";
import AutoVideoPlayer from "./AutoVideoPlayer";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {Sticky, StickyContainer} from 'react-sticky';
import {FormattedMessage} from "react-intl";
import { CheckBox } from 'react-admin-base-bootstrap';
import {useBrand} from "../providers/BrandProvider";

const store = createFetchStore(() => api("/portal/template"));

const typeFilters = {
    'HORIZONTAL': a => a > 1,
    'VERTICAL': a => a < 1 && a !== 1080/1350,
    'SQUARE': a => a === 1,
    'POST': a => a === 1080/1350
};

function filterTemplates(templates, filters) {
    var filtered = templates;

    if (filters.category_ids && filters.category_ids.length > 0) {
        filtered = filtered.filter(a => filters.category_ids.find(b => a.template.old_categories.indexOf(b) !== -1));
    }

    if (filters.type_ids && filters.type_ids.length > 0) {
        filtered = filtered.filter(a => a.template.preview_file && a.template.preview_file.video
            && filters.type_ids
                .find(b => typeFilters[b](
                    a.template.preview_file.video.width / a.template.preview_file.video.height
                ))
        );
    }

    return filtered;
}

export default function TemplateList() {
    const brand = useBrand();
    store.prefetch();
    const templates = store.get();
    const [ filters, setFilters ] = useState({});

    const filteredTemplates = useMemo(() => filterTemplates(templates, filters), [templates, filters]);

    return <div className="template-list">
        <StickyContainer>
            <Row>
                { !brand.compact_mode && <Col md="3">
                    <Sticky>
                        {
                            ({ style }) =>
                                <div style={style} className="mt-2">
                                    <Filters
                                        value={filters}
                                        onChange={setFilters}
                                        templates={templates}
                                    />
                                </div>
                        }
                    </Sticky>
                </Col> }
                <Col className="mt-2">
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 4}}
                    >
                        <Masonry gutter="20px">
                            { filteredTemplates.map(a => <Template value={a} key={a.template.id} />) }
                        </Masonry>
                    </ResponsiveMasonry>
                </Col>
            </Row>
        </StickyContainer>
    </div>;
}

function Filters({ value, onChange, templates, style }) {
    const categoryNames = useMemo(() => templates
        .reduce((a,b) => ({
            ...a,
            ...b.template.old_categories
                .reduce((c, d) => ({ ...c, [d]: (a[d] || 0) + 1 }), {})
        }), {}), [templates]);
    const filterNameW = useMemo(() => templates
        .filter(a => a.template.preview_file && a.template.preview_file.video)
        .reduce((a,b) => ({
            ...a,
            ...Object.entries(typeFilters)
                .reduce((c, [name, filter]) => ({ ...c, [name]: (a[name] || 0) + filter(
                    b.template.preview_file.video.width / b.template.preview_file.video.height
                ) }), {})
        }), {}), [templates]);

    const category_ids = (value && value.category_ids) || [];
    const type_ids = (value && value.type_ids) || [];

    return <div className="filters" style={style}>
        <h5><FormattedMessage id="DIMENSIONS" /></h5>
        <ul>
            {
                Object.entries(filterNameW)
                    .map(([name, count]) => <li>
                        <CheckBox
                            id={name}
                            type="checkbox"
                            label={<FormattedMessage id={"DIMEN_" + name } />}
                            checked={type_ids.indexOf(name) !== -1}
                            onChange={e => onChange({
                                category_ids: category_ids,
                                type_ids: type_ids.indexOf(name) === -1 ?
                                    type_ids.concat([name]) :
                                    type_ids.filter(b => b !== name)
                            })}
                        />
                        <span>
                            { count }
                        </span>
                    </li>)
            }
        </ul>
        <hr />

        <h5><FormattedMessage id="CATEGORIES" /></h5>
        <ul>
            {
                Object.entries(categoryNames)
                    .map(([ name, count ]) => <li>
                        <CheckBox
                            id={name}
                            type="checkbox"
                            label={name}
                            checked={category_ids.indexOf(name) !== -1}
                            onChange={e => onChange({
                                type_ids: type_ids,
                                category_ids: category_ids.indexOf(name) === -1 ?
                                    category_ids.concat([name]) :
                                    category_ids.filter(b => b !== name)
                            })}
                        />
                        <span>
                            { count }
                        </span>
                    </li>)
            }
        </ul>
    </div>;
}

function GetVideoType(video) {
    if (!video)
        return null;

    const ratio = video.width / video.height;

    if (ratio === 1)
        return "SQUARE";
    else if(ratio === 1080/1350)
        return "POST";
    else if (ratio < 1)
        return "VERTICAL";
    else if(ratio > 1) {
        return "HORIZONTAL";
    }
}

function Template({ value }) {
    const video = value.template.preview_file && value.template.preview_file.video;
    const type = GetVideoType(video);

    return <Link className="single-template" to={video ? "/template/" + value.template.slug : "/template/" + value.template.slug + "/customize"}>
        <AutoVideoPlayer
            value={video}
        />
        <div className="small-info">
            <span>
                <FormattedMessage
                    id="META_SECONDS"
                    values={{ value: Math.floor(value.template.frame_count / value.template.fps) || 0 }}
                />
            </span>
            { value.template.image_count > 0 && <span>
                <FormattedMessage
                    id="META_PHOTO_COUNT"
                    values={{ value: value.template.image_count }}
                />
            </span> }
            { value.template.text_count > 0 && <span>
                <FormattedMessage
                    id="META_TEXT_COUNT"
                    values={{ value: value.template.text_count }}
                />
            </span>}
            <span><FormattedMessage id={"DIMEN_" + type } /></span>
        </div>
        <div className="info">
            <h3>
                { value.name || value.template.name }
            </h3>
            <h4>
                <PriceSpan price={value.price} />
            </h4>
        </div>
    </Link>;
}


