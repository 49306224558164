import {useIntl} from "react-intl";
import {ValidatorOptionProvider} from 'react-admin-base';
import {useMemo} from "react";


export default function ValidatorOptionsProvider({ children }) {
    const intl = useIntl();

    const options = useMemo(() => ({
        validators: {
            card: {
                message: intl.formatMessage({ id: 'VALIDATOR_INVALID' }),
                rule: (val, params, validator) => {
                    return val && val.complete;
                }
            }
        },
        messages: {
            email: intl.formatMessage({ id: 'VALIDATOR_EMAIL' }),
            required: intl.formatMessage({ id: 'VALIDATOR_REQUIRED' }),
            numberic: intl.formatMessage({ id: 'VALIDATOR_NUM' }),
            accepted: intl.formatMessage({ id: 'VALIDATOR_ACCEPT' }),
            in: intl.formatMessage({ id: 'VALIDATOR_EQ' }),
        }
    }), [intl]);

    return <ValidatorOptionProvider value={options}>
        { children }
    </ValidatorOptionProvider>
}
