import React, {useCallback, useState} from "react";
import {Button, Col, Form, Row} from 'reactstrap';
import {ValidatorProvider} from "react-admin-base";
import {FormattedMessage} from "react-intl";

export default function StepContainer({ children, onSubmit }) {
    const [ index, setIndex ] = useState(0);
    const [ loading, setLoading ] = useState(false);

    const validChilds = children
        .filter(a => !!a);

    const handleNext = useCallback(function(e) {
        e.preventDefault();

        if (index === validChilds.length - 1) {
            const prom = onSubmit && onSubmit();
            if (prom && prom.then) {
                setLoading(true);
                prom
                    .then(a => setLoading(false))
                    .catch(a => setLoading(false));
            }
        } else {
            setIndex(index => index + 1);
        }
    }, [ index, validChilds.length, setIndex, onSubmit, setLoading ]);

    return <div>
        <ul className="modules">
            {
                validChilds
                    .map((a, i) => <li key={i} className={ index === i ? "active" : index > i ? "past" : "future" }>
                        <span onClick={i >= index ? null : setIndex.bind(null, i)}>
                            <span><FormattedMessage id="STEP" values={{ index: i + 1 }} /></span><br />
                            <span><FormattedMessage id={ a.props.title } /></span>
                        </span>
                    </li>)
            }
        </ul>
        <hr />
        <ValidatorProvider>
            <Form onSubmit={handleNext}>
                {
                    React.cloneElement(validChilds[index], {
                        prev: index === 0 ? null : setIndex.bind(null, index - 1),
                        isFinal: index === validChilds.length - 1,
                        loading: loading
                    })
                }
            </Form>
        </ValidatorProvider>
    </div>
}

export function Step({ title, children, prev, isFinal, customBtn, loading }) {
    return <div>
        { children }
        <Row>
            <Col>
                <Button type="button" color="danger" block outline onClick={prev} disabled={!prev || loading}>
                    <FormattedMessage id="STEP_PREVIOUS" />
                </Button>
            </Col>
            <Col>
                { customBtn || <Button className="ms-1" block color="primary" disabled={loading}>
                    { loading && <i className="fas fa-spin fa-spinner me-1" /> }
                    <FormattedMessage id={ isFinal ? 'STEP_FINALIZE' : 'STEP_NEXT' } />
                </Button> }
            </Col>
        </Row>
    </div>
}
