import React, {createContext, useCallback, useContext, useReducer, useRef} from 'react';
import api from "../api";
import tokenized_api from "../tokenized_api";

const AuthContext = createContext();
export function useAuth() {
    return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
    const ref = useRef();
    const [ _api, setToken ] = useReducer((_, [access_token, refresh_token]) => {
        if (access_token) {
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
        } else {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
        }

        return tokenized_api(
            access_token,
            refresh_token,
            token => {
                if (token) {
                    localStorage.setItem('access_token', token);
                } else {
                    ref.current([]);
                }
            }
        );
    }, 0, _ => {
        return tokenized_api(
            localStorage.getItem('access_token'),
            localStorage.getItem('refresh_token'),
            token => {
                if (token) {
                    localStorage.setItem('access_token', token);
                } else {
                    ref.current([]);
                }
            }
        );
    });

    ref.current = setToken;

    const login = useCallback(async function(username, password) {
        let formData = new FormData();

        formData.append('client_id', '3da4385d-5166-4c1f-9c4b-49afe82be47e');
        formData.append('client_secret', '123456');
        formData.append('grant_type', 'password');
        formData.append('username', username);
        formData.append('password', password);

        const data = await api('/portal/token', {
            method: 'POST',
            body: formData
        });

        setToken([ data.access_token, data.refresh_token ]);
    }, [ setToken ]);

    const ctx = React.useMemo(() => [ _api, login, setToken ], [ _api, login, setToken ]);

    return <AuthContext.Provider value={ctx}>
        { children }
    </AuthContext.Provider>;
}
