import {createFetchStore} from 'react-suspense-fetch';
import api from "../api";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {ModalFooter, ModalHeader} from "reactstrap";
import {Link, Route, Routes, useParams} from "react-router-dom";
import ForcedModal from "../components/ForcedModal";
import LoadingModal from "../components/LoadingModal";
import {VideoPlayerBig} from "../components/AutoVideoPlayer";
import Customize from "./customize";
import {FormattedMessage} from "react-intl";
import PriceSpan from "../components/PriceSpan";

export const store = createFetchStore(id => api("/portal/template/" + id));

export default function Template() {
    return <LoadingModal size="lg">
        <Routes>
            <Route path="customize" element={<Customize />} />
            <Route path="*" element={<TemplateDetails />} />
        </Routes>
    </LoadingModal>;
}

function TemplateDetails() {
    const params = useParams();
    store.prefetch(params.id);
    const template = store.get(params.id);

    return <ForcedModal size="lg">
        <ModalHeader>
            { template.name || template.template.name }
        </ModalHeader>
        <VideoPlayerBig
            value={template.template.preview_file.video}
        />
        <ModalFooter>
            <Link to={"/template/" + template.template.slug + "/customize"} className="btn btn-primary btn-lg d-block flex-grow-1">
                <FormattedMessage id="CUSTOMIZE_VIDEO" values={{
                    title: template.name || template.template.name,
                    price: <PriceSpan price={template.price} />
                }} />
            </Link>
        </ModalFooter>
    </ForcedModal>
}