import {useAuth} from "./AuthProvider";
import {createFetchStore} from "react-suspense-fetch";
import {createContext, useContext} from "react";

const store = createFetchStore(a => a("/portal/user"));
const UserContext = createContext();

export default function UserProvider({ children }) {
    const [ api ] = useAuth();

    store.prefetch(api);
    const user = api && store.get(api);

    return <UserContext.Provider value={user}>
        { children }
    </UserContext.Provider>
}

export function useUser() {
    return useContext(UserContext);
}
