import {Alert, Button, Form, FormGroup, Input, Label, ModalBody, ModalHeader} from "reactstrap";
import ForcedModal from "../components/ForcedModal";
import {useAuth} from "../providers/AuthProvider";
import {useCallback, useState} from "react";
import {ValidatorProvider} from "react-admin-base";
import {Validator} from 'react-admin-base-bootstrap';
import api from "../api";
import OtpModal from "../components/OtpModal";
import {FormattedMessage} from "react-intl";

export default function Register() {
    const [,login] = useAuth();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState();
    const [ name, setName ] = useState();
    const [ username, setUsername ] = useState();
    const [ password, setPassword ] = useState();
    const [ password2, setPassword2 ] = useState();
    const [ url, setUrl ] = useState();

    const submitFirst = useCallback(async function(e) {
        e.preventDefault();

        setError(null);
        setLoading(true);
        try {
            const url = await api('/portal/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify({
                    name,
                    email: username,
                    password: password
                })
            });
            setUrl(url);
        } catch(e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }, [setLoading, setUrl, name, username, password, setError]);

    const registerFinal = useCallback(async function (url) {
        setError(null);
        setLoading(false);
        try {
            await api(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify({
                    name,
                    email: username,
                    password: password
                })
            });
            await login(username, password);
        } catch(e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }, [ name, username, password, login, setLoading, setError ]);

    if (url) {
        return <OtpModal
            url={url}
            email={username}
            onValidated={registerFinal}
        />;
    }

    return <ForcedModal>
        <ModalHeader>
            <FormattedMessage id="REGISTER" />
        </ModalHeader>
        <ModalBody>
            <ValidatorProvider>
                <Form onSubmit={submitFirst}>
                    <FormGroup>
                        <Label><FormattedMessage id="NAME" /></Label>
                        <Validator name="name" type="required">
                            <Input
                                type="text"
                                value={name || ''}
                                onChange={a => setName(a.currentTarget.value)}
                                disabled={!!loading}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="EMAIL_ADDRESS" /></Label>
                        <Validator name="email" type="required|email">
                            <Input
                                type="email"
                                value={username || ''}
                                onChange={a => setUsername(a.currentTarget.value)}
                                disabled={!!loading}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="PASSWORD" /></Label>
                        <Validator name="password" type="required">
                            <Input
                                type="password"
                                value={password || ''}
                                onChange={a => setPassword(a.currentTarget.value)}
                                disabled={!!loading}
                                autoComplete="new-password"
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="PASSWORD_AGAIN" /></Label>
                        <Validator name="password2" type={["required", { in: password }]}>
                            <Input
                                type="password"
                                value={password2 || ''}
                                onChange={a => setPassword2(a.currentTarget.value)}
                                disabled={!!loading}
                                autoComplete="new-password"
                            />
                        </Validator>
                    </FormGroup>
                    { error && <Alert color="danger" size="sm" toggle={a => setError(null)}>
                        { error.message }
                    </Alert> }
                    <Button type="submit" block color="primary" disabled={!!loading}>
                        <FormattedMessage id="REGISTER" />
                    </Button>
                </Form>
            </ValidatorProvider>
        </ModalBody>
    </ForcedModal>;
}
