import Layout from "../layout/Layout";
import {ModalRoutes} from "../router";
import Banners from "../components/Banners";
import TemplateList from "../components/TemplateList";
import {Container} from "reactstrap";

export default function Home() {
    return <Layout>
        <Banners />
        <Container>
            <TemplateList />
        </Container>
        <ModalRoutes />
    </Layout>;
}
