import ForcedModal from "../components/ForcedModal";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LoadingModal from "../components/LoadingModal";
import {Navigate} from "react-router-dom";
import {useState} from "react";
import {createFetchStore} from "react-suspense-fetch";
import api from "../api";
import {FormattedMessage} from "react-intl";

export const store = createFetchStore(id => api("/portal/about-us"));

export default function AboutUs() {
    return <LoadingModal size="lg">
        <AboutUsInner />
    </LoadingModal>;
}

function AboutUsInner() {
    const [ closed, setClosed ] = useState(false);
    store.prefetch();
    const policyText = store.get();

    if (closed)
        return <Navigate to="/" />;

    return <ForcedModal size="lg">
        <ModalHeader>
            <FormattedMessage id="ABOUT_US" />
        </ModalHeader>
        <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: policyText }} />
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="danger" outline block onClick={a => setClosed(true)}>
                <FormattedMessage id="CLOSE" />
            </Button>
        </ModalFooter>
    </ForcedModal>
}

export function AboutUsPopup({ onClose }) {
    store.prefetch();
    const policyText = store.get();

    return <Modal isOpen size="lg" toggle={onClose}>
        <ModalHeader toggle={onClose}>
            <FormattedMessage id="ABOUT_US" />
        </ModalHeader>
        <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: policyText }} />
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="danger" outline block onClick={onClose}>
                <FormattedMessage id="CLOSE" />
            </Button>
        </ModalFooter>
    </Modal>
}