import {Alert, Button, Col, Form, FormGroup, Input, Label, ModalBody, ModalHeader, Row} from "reactstrap";
import ForcedModal from "../components/ForcedModal";
import {useAuth} from "../providers/AuthProvider";
import {useCallback, useState} from "react";
import {ValidatorProvider} from "react-admin-base";
import {Validator} from 'react-admin-base-bootstrap';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

export default function Login() {
    const [,login] = useAuth();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState();
    const [ username, setUsername ] = useState();
    const [ password, setPassword ] = useState();

    const submit = useCallback(async function(e) {
        e.preventDefault();

        setError(null);
        setLoading(true);
        try {
            await login(username, password);
        } catch(e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }, [setLoading, login, username, password, setError]);

    return <ForcedModal>
        <ModalHeader>
            <FormattedMessage id="LOGIN" />
        </ModalHeader>
        <ModalBody>
            <ValidatorProvider>
                <Form onSubmit={submit}>
                    <FormGroup>
                        <Label><FormattedMessage id="EMAIL_ADDRESS" /></Label>
                        <Validator name="email" type="required|email">
                            <Input
                                type="email"
                                value={username || ''}
                                onChange={a => setUsername(a.currentTarget.value)}
                                disabled={!!loading}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="PASSWORD" /></Label>
                        <Validator name="password" type="required">
                            <Input
                                type="password"
                                value={password || ''}
                                onChange={a => setPassword(a.currentTarget.value)}
                                disabled={!!loading}
                            />
                        </Validator>
                    </FormGroup>
                    { error && <Alert color="danger" size="sm" toggle={a => setError(null)}>
                        { error.message }
                    </Alert> }
                    <Button type="submit" block color="primary" disabled={!!loading}>
                        <FormattedMessage id="LOGIN" />
                    </Button>
                    <Row className="mt-2">
                        <Col>
                            <Link className="btn btn-outline-primary w-100" to="/forgot">
                                <FormattedMessage id="RESET_PASSWORD" />
                            </Link>
                        </Col>
                        <Col className="text-right">
                            <Link className="btn btn-outline-primary w-100" to="/register">
                                <FormattedMessage id="REGISTER" />
                            </Link>
                        </Col>
                    </Row>
                </Form>
            </ValidatorProvider>
        </ModalBody>
    </ForcedModal>;
}
