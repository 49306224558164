import React from 'react';
import {createFetchStore} from 'react-suspense-fetch';
import api from "../api";
import {useBrand} from "./BrandProvider";
import {IntlProvider} from "react-intl";

const store = createFetchStore(url => api(url));

export default function LanguageProvider({ children }) {
    const brand = useBrand();
    const language = brand.language;
    store.prefetch(brand.language.precompiled_json.access_url);
    const messages = store.get(brand.language.precompiled_json.access_url);

    return <IntlProvider messages={messages} locale={language.language_code}>
        { children }
    </IntlProvider>;
}
