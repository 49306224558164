import React, {useRef, useCallback, useMemo, useState} from 'react';
import AspectRatio from 'react-aspect-ratio';
import ReactPlayer from "react-player/file";
import NoSignalLogo from '../assets/images/no-signal.png';

const playerConfig = {
    file: {
        hlsVersion: '0.14.7'
    }
}

const Player = React.memo(function({ value }) {
    const [ playing, setPlaying ] = useState(false);
    const [ playedOnce, setPlayedOnce ] = useState(false);

    const timerRef = useRef();

    const onMouseEnter = useCallback(function() {
        timerRef.current = setTimeout(function() {
            timerRef.current = null;
            setPlaying(true);
            setPlayedOnce(true);
        }, 850);
    }, [timerRef, setPlaying, setPlayedOnce]);

    const onMouseLeave = useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }

        setPlaying(false);
    }, [timerRef, setPlaying]);

    if (!value) {
        return <AspectRatio ratio={16/9}>
            <img src={NoSignalLogo} alt="No signal" />
        </AspectRatio>;
    }

    return <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <AspectRatio ratio={value.width / value.height}>
            <ReactPlayer
                playing={playing}
                controls={playing}
                muted
                light={!playedOnce && value.pictures[value.pictures.length - 1].link}
                url={value.hls_link}
                width='100%'
                height='100%'
                config={playerConfig}
            />
        </AspectRatio>
    </div>;
});

export function VideoPlayerBig({ value }) {
    return <AspectRatio ratio={value.width / value.height}>
        <ReactPlayer
            playing
            controls
            url={value.hls_link}
            width='100%'
            height='100%'
            config={playerConfig}
        />
    </AspectRatio>;
}

export default Player;