import React, {useCallback} from 'react';
import {UploadProvider} from "react-admin-base";
import * as SparkMD5 from 'spark-md5'
import api from "./api";

function computeChecksumMd5(file) {
    return new Promise((resolve, reject) => {
        const chunkSize = 2097152;
        const spark = new SparkMD5.ArrayBuffer();
        const fileReader = new FileReader();

        let cursor = 0;

        fileReader.onerror = function() {
            reject('MD5 computation failed - error reading the file');
        };

        function processChunk(chunk_start) {
            const chunk_end = Math.min(file.size, chunk_start + chunkSize);
            fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
        }

        fileReader.onload = function(e) {
            spark.append(e.target.result);
            cursor += chunkSize;

            if (cursor < file.size) {
                processChunk(cursor);
            } else {
                resolve(btoa(spark.end(true)));
            }
        };

        processChunk(0);
    });
}

const fields = ['id', 'access_url', 'name', 'size', 'video'];

export default function UploadConfig({ children }) {
    const uploader = useCallback(async function(name, blob, contentType, abort, progress) {
        try {
            const hash = await computeChecksumMd5(blob);

            const ticket = await api(`/file/${encodeURIComponent(name)}/start?hash=${encodeURIComponent(hash)}`, {
                method: 'PUT'
            });

            if (!ticket.upload_url)
                return ticket;

            await new Promise(function (resolve, reject) {
                const request = new XMLHttpRequest();

                request.upload.addEventListener("progress", e => progress(e.loaded / e.total));
                request.addEventListener('abort', () => reject(new Error('Upload has aborted.')));
                request.addEventListener('error', () => reject(new Error('Upload error.')));
                request.addEventListener('load', resolve);

                request.open("PUT", ticket.upload_url, true);
                request.setRequestHeader('Content-Type', contentType);
                request.setRequestHeader('x-ms-date', (new Date()).toGMTString());
                request.setRequestHeader('x-ms-version', '2020-04-08');
                request.setRequestHeader('x-ms-blob-type', 'BlockBlob');
                request.send(blob);
            });

            return await api(ticket.complete_url, {
                method: 'DELETE'
            });
        } catch(e) {
            console.error('Upload error:', e);
            throw e;
        }
    }, [ ]);

    return <UploadProvider uploader={uploader} fields={fields}>
        { children }
    </UploadProvider>
}
