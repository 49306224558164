import {Container} from "reactstrap";
import {useBrand} from "../providers/BrandProvider";
import {useUser} from "../providers/UserProvider";
import {Link} from "react-router-dom";
import {useAuth} from "../providers/AuthProvider";
import {useCallback} from "react";
import {FormattedMessage} from "react-intl";

export default function Header() {
    const brand = useBrand();
    const user = useUser();

    return <header className="default">
        <Container>
            <Logo />
            <div className="">
                <ul>
                    { brand.intro_url && <li>
                        <a href={brand.intro_url}>
                            <FormattedMessage id="INTRODUCTION" />
                        </a>
                    </li> }
                    { !brand.disable_login && <li>
                        <Link to={user ? "/my-videos" : "/login?url=/my-videos"}>
                            <FormattedMessage id="MY_VIDEOS" />
                        </Link>
                    </li> }
                    { brand.about_us_enabled && <li>
                        <Link to="/about-us">
                            <FormattedMessage id="ABOUT_US" />
                        </Link>
                    </li> }
                    { brand.contact_form_enabled && <li>
                        <Link to="/contact">
                            <FormattedMessage id="CONTACT" />
                        </Link>
                    </li> }
                </ul>
                <UserMenu />
            </div>
        </Container>
    </header>;
}

function Logo() {
    const brand = useBrand();

    if (!brand.logo)
        return null;

    return <img src={brand.logo.access_url} className="logo" alt={brand.name} />;
}

function UserMenu() {
    const user = useUser();
    const [ ,, logout ] = useAuth();
    const brand = useBrand();

    const handleLogout = useCallback(e => {
        e.preventDefault();
        logout([]);
    }, [logout]);

    if (brand.disable_login)
        return null;

    if (!user) {
        return <div>
            <Link to="/login" className="header-btn">
                <FormattedMessage id="LOGIN" />
            </Link>
            <Link to="/register" className="header-btn">
                <FormattedMessage id="REGISTER" />
            </Link>
        </div>;
    }

    return <div>
        <FormattedMessage id="HELLO" values={{ name: <b>{ user.name }</b> }} />
        <Link to="/logout" className="ms-3 header-btn" onClick={handleLogout}>
            <FormattedMessage id="LOGOUT" />
        </Link>
    </div>;
}
